.form-control {
    width: 300px;
}
form {
    width: 350px;
    margin: auto;
    /*align-items: center;*/
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30%;
    width: 50%;
    margin: -15% 0 0 -25%;
}

main {
    height: 800px;
    position: relative;
}